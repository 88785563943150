import React, { useContext } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { AuthContext } from '../context';
import About from '../pages/About';
import Home from '../pages/Home';
import IndividualPost from '../pages/IndividualPost';
import NotFound from '../pages/NotFound';
import Posts from '../pages/Posts';
import { privateRoutes, publicRoutes } from '../router/index';
import ReactLoader from './UI/loader/ReactLoader';

const AppRoutes = () => {

    const { isAuth, isAuthLoading } = useContext(AuthContext);

    if( isAuthLoading ) {
        return <ReactLoader />
    }

    return (

        <div className="page-content-wrapper">
        
            { isAuth 
            ?
                <Routes>
                    {privateRoutes.map( route =>
                        <Route 
                            key={ route.path }
                            path={ route.path } 
                            element={ <route.element /> }  
                        />
                    )}
                </Routes>
            :
                <Routes>
                    {publicRoutes.map( route =>
                        <Route 
                            key={ route.path }
                            path={ route.path } 
                            element={ <route.element /> }  
                        />
                    )}
                </Routes>
            }
            
        </div>

    );

}

export default AppRoutes