import React from 'react';
import { usePagination, getPagesCount } from '../../../utils/pagination';

const Pagination = ({ totalPages, currentPostsPage, changePage }) => {

    let pagesArray = usePagination(totalPages);

    return (

        <div className="pagination-wrapper">
            { pagesArray.map( page => 
                <span 
                    key={ page } 
                    onClick={ () => changePage(page) }
                    className={ page === currentPostsPage ? 'pagination-item active' : 'pagination-item' }
                >
                    { page }
                </span> 
            ) }
        </div>

    );
    
}

export default Pagination