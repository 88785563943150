import React from 'react';
import ReactSelect from './UI/select/ReactSelect';
import ReactInput from './UI/input/ReactInput';

const PostFilter = ({ filter, setFilter }) => {

    const postsLimitChange = (value) => {

        setFilter({ ...filter, 'limit': value});
        localStorage.setItem('limit', value);

    }
    
    return (
        <div>
            <ReactInput
                placeholder="Search"
                value={ filter.query }
                onChange={ e => setFilter({ ...filter, query: e.target.value}) }
            />
            
            <div className="select-filter-wrapper">

                <ReactSelect 
                    value={ filter.sort }
                    onChange={ selectedSort => setFilter({ ...filter, sort: selectedSort}) }
                    defaultValue='Sort by' 
                    options={[ 
                        { value: 'title', label: 'Title' },
                        { value: 'body', label: 'Description' }
                    ]}
                />

                <ReactSelect 
                    value={ filter.limit }
                    onChange={ postsLimitChange }
                    defaultValue="Count of posts"
                    options={[
                        { value: 5, label: '5' },
                        { value: 10, label: '10' },
                        { value: 20, label: '20' },
                        { value: 50, label: '50' },
                        { value: -1, label: 'Show all' },
                    ]}
                />

            </div>

        </div>
    );

}

export default PostFilter;