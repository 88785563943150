import { useMemo } from 'react';

/*
* Custom Hooks
*/


// Get sorted posts if selectedSort value is set
export const useSortedPosts = (posts, sort) => {

    // console.log('useSortedPosts hook');

    const sortedPosts = useMemo( () => {

		if( sort ) {
			return [...posts].sort( (a, b) => a[sort].localeCompare(b[sort]) );
		}

		return posts;

	}, [sort, posts] );

    return sortedPosts;

}

// Get sorted and searched posts
export const usePosts = (posts, sort, query) => {

    // console.log('usePosts hook');

    const sortedPosts = useSortedPosts(posts, sort);

    const sortedAndSearchedPosts = useMemo( () => {

		return sortedPosts.filter( post => post.title.toLowerCase().includes(query.toLowerCase()) );

	}, [query, sortedPosts] );

    return sortedAndSearchedPosts;

}