import React from 'react'
import classes from './ReactLoader.module.css';

const ReactLoader = () => {
    return (
        <div className={ classes.loaderWrapper }>
            <div className={ classes.loader }></div>
        </div>
    )
}

export default ReactLoader