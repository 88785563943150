import React from 'react'

const IndividualPostContent = ({ post }) => {

    return (
        <div>
            <h1>{ post.title }</h1>
            <div>{ post.body }</div>
        </div>
    )

}

export default IndividualPostContent