import About from "../pages/About";
import Home from "../pages/Home";
import IndividualPost from "../pages/IndividualPost";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Posts from "../pages/Posts";

export const privateRoutes = [
    { path: '/', element: Home },
    { path: '/about', element: About },
    { path: '/posts', element: Posts },
    { path: '/posts/:id', element: IndividualPost },
    { path: '*', element: NotFound }
];


export const publicRoutes = [
    { path: '/login', element: Login },
    { path: '*', element: Login }
];