import React from 'react';
import ReactButton from './UI/button/ReactButton';
import { useNavigate } from 'react-router-dom';

const PostItem = (props) => {

    const navigate = useNavigate();
    
    return (
        <div className="post-item">
            <div className="post-content">
                <h2>{ props.post.title }</h2>
                <div className="data-description">
                    { props.post.body }
                </div>
            </div>
            <div className="post-button">
                <ReactButton
                    onClick={ () => navigate(`/posts/${props.post.id}`) }
                >
                    Open
                </ReactButton>
                <ReactButton 
                    onClick={() => props.remove(props.post)} 
                    data-id={props.post.id}
                >
                    Remove
                </ReactButton>
            </div>
        </div>
    );

}

export default PostItem;