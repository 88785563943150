import React, { useContext } from 'react'
import ReactInput from '../components/UI/input/ReactInput';
import ReactButton from '../components/UI/button/ReactButton';
import { AuthContext } from '../context';

const Login = () => {

    const {isAuth, setIsAuth } = useContext(AuthContext);

    const login = (e) => {

        e.preventDefault();
        
        setIsAuth(true);
        localStorage.setItem('auth', true);

    }

    return (
        <div>
            <h1>Log in Page</h1>
            <form onSubmit={ login }>
                <ReactInput type="text" placeholder="Login" />
                <ReactInput type="password" placeholder="Password" />
                <ReactButton>Log in</ReactButton>
            </form>
        </div>
    );
    
}

export default Login