import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetPosts from '../API/GetPosts';
import IndividualPostComments from '../components/IndividualPostComments';
import IndividualPostContent from '../components/IndividualPostContent';
import ReactLoader from '../components/UI/loader/ReactLoader';
import { useFetching } from '../hooks/useFetching';

const IndividualPost = () => {

    const params = useParams();
    const [post, setPost] = useState({});
    const [comments, setComments] = useState([]);
    
    // Get posts data from Server using custom Hook
	const [fetchPostById, isPostsLoading, postError] = useFetching( async (id) => {

		const post_data = await GetPosts.getById(id);
        setPost(post_data.data);

	});

    // Get comment data from Server using custom Hook
	const [fetchPostComments, isCommentsLoading, commentsError] = useFetching( async (id) => {

		const comment_data = await GetPosts.getComments(id);
        setComments(comment_data.data);

	});
	

    useEffect( () => {

        fetchPostById(params.id);
        fetchPostComments(params.id);

    }, []);

    return (
        <div>
            { isPostsLoading
                ? <ReactLoader />
                : <IndividualPostContent post={ post } />
            }
            { isCommentsLoading
                ? <ReactLoader />
                : <IndividualPostComments comments={ comments } />
            }
        </div>
    )

}

export default IndividualPost