import React from 'react';
import classes from './ReactInput.module.css';

const ReactInput = (props) => {

    return (
        <input className={classes.reactInput} {...props}/>
    );

}

export default ReactInput;