import React from 'react';
import classes from './ReactButton.module.css';

const ReactButton = ({ children, ...props }) => {

    let el_classes = classes.reactButton;

    if( {...props}.className !== undefined ) {
        el_classes += ' ' + {...props}.className;
    }

    return (
        <button 
            {...props} 
            className={ el_classes }
        >
            { children }
        </button>
    );

}

export default ReactButton;