import { useMemo } from 'react';

export const getPagesCount = (totalCount, limit) => {

    return Math.ceil(totalCount / limit);

}

export const usePagination = ( totalPages ) => {

    const pagesArray = useMemo( () => {

        let paginationArray = [];
        for( let i = 0; i < totalPages; i++ ) {
            paginationArray.push(i+1);
        }
		return paginationArray;

	}, [totalPages] );

    return pagesArray;

}