import React from 'react';
import classes from './ReactTextarea.module.css';

const ReactTextarea = React.forwardRef( ({ children, ...props }, ref) => {

    return (
        <textarea ref={ref} className={classes.reactTextarea} {...props}>{ children }</textarea>
    );

});

export default ReactTextarea;