import React from 'react';

const ReactSelect = ({ options, defaultValue, value, onChange }) => {

    return (
        <select 
            value={value} 
            onChange={ e => onChange(e.target.value) }
        >

            <option disabled defaultValue value="">{ defaultValue }</option>

            {options.map(option =>
                <option 
                    key={ option.value } 
                    value={ option.value }
                >{ option.label }</option>
            )};

        </select>
    );

}

export default ReactSelect;
