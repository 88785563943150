import React from 'react';
import classes from './ReactModal.module.css';

const ReactModal = ({ children, visible, setVisible }) => {

    const rootClasses = [classes.reactModal];

    if( visible ) {
        rootClasses.push(classes.active);
    }

    return (
        <div 
            className={ rootClasses.join(' ') } 
            onClick={ () => setVisible(false) }
        >
           <div 
                className={classes.reactModalContent}
                onClick={ (e) => e.stopPropagation() }
            >
                { children }
            </div> 
        </div>
    );

};

export default ReactModal;