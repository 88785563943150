import React from 'react'

const IndividualPostComments = ({ comments }) => {

    console.log(comments); 

    return (
        <div className="post-comment">
            <h2 className="data-title">Comments:</h2>
            {comments.map( comment =>
                <div className="comment-item" key={ comment.id }>
                    <div className="data-name">{ comment.name } <span>&lt;{ comment.email }&gt;</span> </div>
                    <div>{ comment.body }</div>
                </div>
            )}
        </div>
    )

}

export default IndividualPostComments