import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import AppRoutes from './components/AppRoutes';
import Header from './components/UI/header/Header';
import { AuthContext } from './context';
import './styles/app.css';

function App() {

	const [isAuth, setIsAuth] = useState(false);
	const [isAuthLoading, setIsAuthLoading] = useState(true);

	useEffect( () => {

		if(localStorage.getItem( 'auth' )) {
			setIsAuth(true);
		}

		setIsAuthLoading(false);

	}, []);

	return(
		<AuthContext.Provider value={{
			isAuth, setIsAuth, isAuthLoading
		}}>
			<BrowserRouter>
				<Header />
				<AppRoutes />
			</BrowserRouter>
		</AuthContext.Provider>
	);

}

export default App;
