import React, { useState } from 'react';
import ReactButton from './UI/button/ReactButton';
import ReactInput from './UI/input/ReactInput';
import ReactTextarea from './UI/textarea/ReactTextarea';

const PostForm = ({ create }) => {

    const [post, setPost] = useState({ title: '', body: '' });

    const addNewPost = (e) => {

		e.preventDefault();

        const newPost = {
            ...post, id: Date.now()
        }

        create(newPost);

		// Clear form element values
		setPost({ title: '', body: '' });

	}

    
    
    return (
        <form>
				
            <ReactInput 
                type="text" 
                placeholder="Title" 
                value={ post.title } 
                onChange={ e => setPost({ ...post, title: e.target.value }) } 
            />

            <ReactTextarea 
                value={ post.body } 
                onChange={ e => setPost({ ...post, body: e.target.value }) } 
                placeholder="Description"
            >
            </ReactTextarea>

            <ReactButton onClick={ addNewPost }>Create Post</ReactButton>

        </form>
    );

}

export default PostForm;