import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../context'
import ReactButton from '../button/ReactButton'

const Header = () => {

    const {isAuth, setIsAuth} = useContext(AuthContext);

    const logout = (e) => {

        setIsAuth(false);
        localStorage.setItem('auth', false);

    }

    return (
        <div className="header">
            <div className="logo-wrapper">
                <Link to="/">Project Logo</Link>
            </div>
            <div className="navigation">
                <ul>
                    <li className="navigation-items">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="navigation-items">
                        <Link to="/about">About</Link>
                    </li>
                    <li className="navigation-items">
                        <Link to="/posts">Posts</Link>
                    </li>
                </ul>
            </div>
            <ReactButton
                onClick={ logout }
                className="header-button"
            >Log out</ReactButton>
        </div>
    );

}

export default Header