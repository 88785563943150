import React, { useState, useRef, useMemo, useEffect } from 'react';
import PostList from '../components/PostList';
import PostForm from '../components/PostForm';
import PostFilter from '../components/PostFilter';
import ReactModal from '../components/UI/modal/ReactModal';
import ReactButton from '../components/UI/button/ReactButton';
import { usePosts } from '../hooks/usePosts';
import axios from 'axios';
import GetPosts from '../API/GetPosts';
import ReactLoader from '../components/UI/loader/ReactLoader';
import { useFetching } from '../hooks/useFetching';
import { getPagesArray, getPagesCount } from '../utils/pagination';
import Pagination from '../components/UI/pagination/Pagination';
import { useObserver } from '../hooks/useObserver';
import ReactSelect from '../components/UI/select/ReactSelect';


function Posts() {

	// Array of existing Posts
	const [posts, setPosts] = useState([]);

	// Some Variables that are used
	const [filter, setFilter] = useState({ 
		sort: '', 
		query: '', 
		limit: ( localStorage.getItem('limit') ? localStorage.getItem('limit') : '10' )
	});

	const [movalVisible, setModalVisible] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	// const [postsLimit, setPostsLimit] = useState(10);
	const [currentPostsPage, setCurrentPostsPage] = useState(1);

	// Get last element on the page to show more posts when it is visible
	// const lastElment = useRef();

	// Get data from Server using custom Hook
	const [fetchPosts, isPostsLoading, postError] = useFetching( async (postsLimit, currentPostsPage) => {

		const posts_data = await GetPosts.getAll(postsLimit, currentPostsPage);
		// setPosts( [...posts, ...posts_data.data] );
		setPosts( [...posts_data.data] );
		const totalPostsCount = posts_data.headers['x-total-count'];
		setTotalPages( getPagesCount(totalPostsCount, postsLimit) );

	});
	
	
	// Get sorted and searched posts using custom hook "UsePosts"
	const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query);

	// !!!
	// useObserver( lastElment, currentPostsPage < totalPages, isPostsLoading, () => {
	// 	setCurrentPostsPage( currentPostsPage + 1 );
	// } );

	useEffect( () => {
		
		fetchPosts(filter.limit, currentPostsPage);

	}, [currentPostsPage, filter.limit]);

	// Create new post function 
	const createPost = (newPost) => {
		
		setPosts( [newPost, ...posts] );
		setModalVisible( false );

	}

	// Remove post function
	const removePost = (post) => {

        setPosts( posts.filter(p => p.id !== post.id) );

    }

	const changePage = (newPage) => {

		setCurrentPostsPage(newPage);

	}


	return (
		<div className="App">

			<ReactButton onClick={ () => setModalVisible(true) }>Create Post</ReactButton>

			{/* Modal Window to add a new post */}
			<ReactModal 
				visible={ movalVisible } 
				setVisible={ setModalVisible } 
			>
				<PostForm create={createPost} />
			</ReactModal>

			<hr style={{ margin: '30px 0' }} />

			{/* Filters (searching, order) */}
			<PostFilter filter={filter} setFilter={setFilter} />

			{/* Show error (if any) */}
			{ postError && <h1>Error: { postError }</h1> }

			<PostList remove={removePost} posts={sortedAndSearchedPosts} title="Posts List" />

			{/* <div ref={ lastElment } style={{ height: 20 }}></div> */}

			{/* Show loader (if loading) or posts */}
			{ isPostsLoading &&
				<ReactLoader />
			}

			{/* Pagination */}
			<Pagination 
				totalPages={ totalPages } 
				currentPostsPage={ currentPostsPage } 
				changePage={ changePage } 
			/>
			
		</div>
	);
	
}

export default Posts;
