import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PostItem from './PostItem';

const PostList = ({ posts, title, remove }) => {

    if( !posts.length ) {
        return (
            <h1 style={{ marginTop: 40, textAlign: 'center' }}>Nothing found</h1>
        );
    }

    return (

        <div>

            <h1 style={{ marginTop: 40, textAlign: 'center' }}>{ title }</h1>

            <TransitionGroup>
                {posts.map((post, index) => 
                    <CSSTransition
                        key={ post.id }
                        timeout={ 500 }
                        classNames="post-item"
                    >
                        <PostItem 
                            remove={remove} 
                            number={index + 1} 
                            post={ post }  
                        />
                    </CSSTransition>
                )}
            </TransitionGroup>

        </div>
    );

}

export default PostList;